<template>
    <div v-if="mayIsee">
    
     
      <b-container>
        <b-row class="mb-3">
          <b-col>
              <b-row>
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          Buscar:
                      </b-input-group-prepend>
                      <b-form-input id="name"
                                  type="text"
                                  name="name"
                                  maxlength="100"
                                  @keyup.enter.native="search"
                                  v-model="form.search"
                                  placeholder="Digite o nome">
                      </b-form-input>
                      <b-button type="button" variant="primary" size="sm" @click="search">
                        <v-wait for="inprocess">
                            <template slot="waiting">
                                Consultando...
                            </template>
                        </v-wait>
                        <span v-if="!grids.integracaos.processing">Consultar</span>
                      </b-button>

                  </b-input-group>
              </b-row>
          </b-col>

        </b-row>
        <b-row>
          <b-table striped="striped"
                  outlined="outlined"
                  class="fontSize tableClicked bg-white"
                  small="small"
                  hover="hover"
                  responsive
                  show-empty
                  empty-text="Não foram encontrados registros."
                  :per-page="grids.integracaos.perPage"
                  v-if="grids.integracaos.loaded"
                  :items="grids.integracaos.items"
                  :fields="grids.integracaos.fields">

              <template slot="ambiente" slot-scope="data">
                  <span v-if="data.item.ambiente ==1 ">Produção</span>
                  <span v-else>Desenvolvimento / Testes</span>
              </template>
              <template slot="active" slot-scope="data">
                  <span v-if="data.item.active == 1">Ativo</span>
                  <span v-else>Inativo</span>
              </template>
           

              <template slot="actions" slot-scope="data">
                  <span v-if="!mayI('integracao-add', 'integracao-wl')">-</span>
                  <b-button-group size="sm" v-if="mayI('integracao-add', 'integracao-wl')">
                      <b-button title="Editar" v-if="mayI('integracao-add')" @click.stop="edit(data.item,$event.target)">Editar</b-button>
                  </b-button-group>
                  <b-button-group size="sm" v-if="data.item.url.length>0" >              
                      <b-button title="Executar" @click.stop="redirect(data.item)" variant="outline-primary" >Executar</b-button>
                  </b-button-group>
              </template>
          </b-table>
          <b-pagination size="sm" v-if="grids.integracaos.loaded && grids.integracaos.total>grids.integracaos.perPage" :total-rows="grids.integracaos.total" v-model="grids.integracaos.currentPage" :per-page="grids.integracaos.perPage">
          </b-pagination>
        </b-row>
          <span id=result_rotinas></span>
      </b-container>
    </div>
</template>

<script>
import Vue from "vue";
import VueHead from 'vue-head';
import VueClipboard from 'vue-clipboard2';
import config from "@/config";
import { func } from "@/functions";
import { integracaoService } from '../../components/common/services/integracao';


Vue.use(VueClipboard);
Vue.use(VueHead);
   
export default {
  mixins: [func],
  name: 'integracao-list',
  head: {
    title: function () {
      return { 
        inner: `TicketOffice | Admin`,
        separator: " | ",
        complement: "Integração - Lista",
      }
    },
  },
  computed: {
    mayIsee() {
      return this.mayI('integracao-viewer', 'integracao-add');
    }
  },
  methods: {
    edit(item) {
  
      this.$router.push(`/integracao/edit/${item.id}`);
    },
    redirect(item) {
     
      //window.open(config.api +url, '_blank');
   
      this.showWaitAboveAll();

      integracaoService.executar(this.getLoggedId(),item.url).then(
     
        response => {     
          $('#result_rotinas').html(response.result);
          item.atualizacao_dados="Agora";
          this.$swal({
            title: ""+response.msg,
            type: "success",
            toast: true,
            position: "top-end",
            showConfirmButton: true,
          
          }).then((result) => {
          
          });     
          this.hideWaitAboveAll();
        },
        error => {
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );  

    },
  
 
    search() {
       
      if (this.processing) return;

      this.grids.integracaos.processing = true;
      this.processing = true;

      this.$wait.start("inprocess");
      this.showWaitAboveAll();
      integracaoService.list(this.getLoggedId(), this.form.search, this.grids.integracaos.currentPage, this.grids.integracaos.perPage).then(
        response => {
          
          this.processing = false;
          this.grids.integracaos.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocess");

          if (this.validateJSON(response))
          {
              this.grids.integracaos.loaded = true;
              this.grids.integracaos.items = response;
              this.grids.integracaos.currentPage = response.length == 0 ? 1 : response[0]["currentPage"];
              this.grids.integracaos.total = response.length == 0 ? 0 : response[0][config.system.totalCount];
          }
        },
        error => {
          this.grids.integracaos.processing = false;
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocess");
          this.toastError("Falha na execução.");
        }
      );      
    }
  },
  data () {
    return {
        processing: false,
        loading: false,
        form: {
          search: ''
        },
        grids: {
            integracaos: {
                processing: false,
                loaded: false,
                total: 0,
                currentPage: 1,
                perPage: 50,
                items: [],
                fields: {
                    nome_parceiro: { label: 'Nome', sortable: false },
                    ambiente: { label: 'Ambiente', sortable: false },
                    active: { label: 'Status', sortable: false },
                    created: { label: 'Criado em', sortable: false },
                    atualizacao_dados: { label: 'Processado rotina em ', sortable: false },
                    actions: { label: 'Ações' }
                },
            }
        }
    }
  }
}
</script>

<style>

</style>
