<template>
    <div>
      <b-container>
        <b-row>
          <b-col>
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text v-bind:class="{ errorFormValidateLabel: ($v.form.name.$invalid) }">
                          Nome:
                      </b-input-group-prepend>
                      <b-form-input id="name"
                                  type="text"
                                  name="name"
                                  v-bind:class="{ errorFormValidateInput: ($v.form.name.$invalid) }"
                                  maxlength="500"
                                  v-model="form.name"
                                  placeholder="Digite o nome">
                      </b-form-input>
                  </b-input-group>
                  <div class="errorFormValidate" v-if="!$v.form.name.required">Campo é obrigatório</div>
              </b-row>
 <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text >
                          CNPJ:
                      </b-input-group-prepend>
                      
                      <b-form-input id="cnpj"
                                  type="text"
                                  name="cnpj"
                                  maxlength="25"
                                  v-mask="'##.###.###/####-##'"
                                  v-model="form.cnpj"
                                  placeholder="Digite o cnpj">
                      </b-form-input>
                  </b-input-group>
                
              </b-row>

              
            
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text >
                          URL rotina : api /v1/....:
                      </b-input-group-prepend>
                      <b-form-input id="url"
                                  type="text"
                                  name="url"
                                  maxlength="800"
                                  v-model="form.url"
                                  placeholder="Digite v1/integrations/eprepag/index">
                      </b-form-input>
                       <div class="" >Utilizado pelo botão executar na listagem</div>
                  </b-input-group>
                 
              </b-row>
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text v-bind:class="{ errorFormValidateLabel: ($v.form.ambiente.$invalid) }">
                          Ambiente:
                      </b-input-group-prepend>
                      <b-form-select v-model="form.ambiente">
                        <option value="prod">Produção</option>
                        <option value="dev" select>Desenvolvimento / testes</option>
                      </b-form-select>
                  </b-input-group>
                  <div class="errorFormValidate" v-if="!$v.form.ambiente.required">Campo é obrigatório</div>
               </b-row>
            
          
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-form-checkbox id="active"
                                      v-model="form.active"
                                      value="1"
                                      unchecked-value="0">
                      <span v-if="form.active == 1">Ativo</span>
                      <span v-else>Inativo</span>
                      </b-form-checkbox>
                  </b-input-group>
              </b-row>
                <b-row>
          <b-col>
            <b-collapse v-model="grids.added.loaded" id="collapse1" class="mt-2">
                <b-row>
                    <b-col>
                      <b-table striped="striped"
                              outlined="outlined"
                              class="fontSize tableClicked bg-white"
                              small="small"
                              hover="hover"
                              responsive
                              show-empty
                              empty-text="Não foram encontrados registros."
                              v-if="grids.added.loaded"
                              :items="grids.added.items"
                              :fields="grids.added.fields">

                          <template slot="key" slot-scope="data">
                              <b-form-input 
                                  type="text"
                                  name="item_name"
                                  maxlength="500"
                                  v-model="data.item.key"
                                  placeholder="Chave">
                              </b-form-input>
                          </template>
                          <template slot="prod" slot-scope="data">
                             <b-form-input 
                                  type="text"
                                  name="item_prod"
                                  maxlength="500"
                                  v-model="data.item.prod"
                                  placeholder="Valor Produção">
                              </b-form-input>

                          </template>
                          <template slot="dev" slot-scope="data">
                            <b-form-input 
                                  type="text"
                                  name="item_dev"
                                  maxlength="500"
                                  v-model="data.item.dev"
                                  placeholder="Valor Desenvolvimento">
                              </b-form-input>

                          </template>
                       
                          
                          <template slot="actions" slot-scope="data">
                              <span v-if="!mayI('ev-add')">-</span>
                              <b-button-group size="sm" >
                                  <b-button title="Excluir" @click.stop="exclude(data.item)">Remover</b-button>
                              </b-button-group>
                          </template>
                      </b-table>

                    </b-col>
                </b-row>
            </b-collapse>
          </b-col>
        </b-row>
         <b-row class="mx-auto mb-3">
          <b-collapse v-model="grids.added.loaded" id="collapse1" class="mt-2 mx-auto mb-3">
              <b-row class="mx-auto mb-3">
                  <b-button title="Incluir" variant="outline-success"  @click.stop="savedays">Adicionar</b-button>
              </b-row>
          </b-collapse>
        </b-row>

              <b-row>
                <b-button type="button" variant="success" size="sm" @click="save">
                  <v-wait for="inprocess">
                      <template slot="waiting">
                          Carregando...
                      </template>
                  </v-wait>
                  <v-wait for="inprocessSave">
                      <template slot="waiting">
                          Salvando...
                      </template>
                  </v-wait>
                  <span v-if="!processing">Salvar</span>
                </b-button>
              </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
</template>

<script>
import Vue from "vue";
import VueHead from 'vue-head';
import VueMask from 'v-mask';
import Moment from 'moment';
import HotelDatePicker from 'vue-hotel-datepicker';
import VueClipboard from 'vue-clipboard2';
import Vuelidate from 'vuelidate';
import { extendMoment } from 'moment-range';
import config from "@/config";
import { func } from "@/functions";
import { integracaoService } from '../../components/common/services/integracao';

import { required } from 'vuelidate/lib/validators';

const moment = extendMoment(Moment);
Vue.use(VueClipboard);
Vue.use(VueHead);
Vue.use(VueMask);
Vue.use(Vuelidate);

export default {
  mixins: [func],
  props: ['id'],
  name: 'integracao-add',
  components: { HotelDatePicker },
  head: {
    title: function () {
      return { 
        inner: `Integração | Admin`,
        separator: " | ",
        complement: `Parceiros - ${this.typeOf}`,
      }
    },
  },
  created() {
    Vue.nextTick().then(response => {
      if (!this.isAdd) {
        this.get();
      }
    });
  },
  computed: {
    mayIregen() {
      return this.mayI('integracao-regen');
    },
    mayIsee() {
      return this.mayI('integracao-add', 'integracao-viewer');
    },
    typeOf() {
      return this.isAdd ? "Adicionar" : "Alterar";
    },
    isAdd() {
      return this.id == '' || this.id == null || this.id == undefined;
    },
  },
  methods: {
     exclude(item) {

  this.grids.added.items.splice( this.grids.added.items.indexOf(item), 1);
    },
     savedays() {
         this.grids.added.items.push({ key:'',dev:'',prod:'' });
    },
    startchanged(date) {
      this.form.selectedDate.start = moment(date).isValid() ? moment(date).format("YYYY-MM-DD") : '';
    },
    endchanged(date) {
      this.form.selectedDate.end = moment(date).isValid() ? moment(date).format("YYYY-MM-DD") : '';
    },
    regenerateNewKey() {
      this.$swal({
        allowEscapeKey: false,
        allowOutsideClick: false,
        allowEnterKey: false,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        title: 'Geração de nova API KEY',
        html: "Deseja realmente gerar uma nova chave de API? <p><small>(A antiga irá parar de funcionar no momento.)</small></p>",
      }).then((result) => {
        if (result.value) {
          if (this.processing) return;

          this.processing = true;

          this.$wait.start("inprocessSave");
          this.showWaitAboveAll();
          integracaoService.regen(this.getLoggedId(), this.id, "prod").then(
            response => {
              this.processing = false;
              this.hideWaitAboveAll();
              this.$wait.end("inprocessSave");

              if (this.validateJSON(response))
              {
                if (response.success) {
                  this.toastSuccess("Chave gerada com sucesso");
                  this.get();
                }
                else {
                  this.toastError(response.msg);
                }
              }
            },
            error => {
              this.processing = false;
              this.hideWaitAboveAll();
              this.$wait.end("inprocessSave");
              this.toastError("Falha na execução.");
            }
          );      
        }
        else if (result.dismiss === this.$swal.DismissReason.cancel) {
        }
      });
    },
  
    save() {
      if (this.processing) return;

      this.processing = true;

      this.$wait.start("inprocessSave");
      this.showWaitAboveAll();
        integracaoService.save(this.getLoggedId(), this.isAdd ? '' : this.id,  this.form.name, this.form.cnpj, this.form.active, this.form.ambiente, this.form.url, this.grids.added.items).then(
        response => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocessSave");

          if (this.validateJSON(response))
          {
             if (response.success) {
               this.toastSuccess("Salvo com sucesso");
               this.$router.push(`/integracao/list`);
             }
             else {
               this.toastError(response.msg);
             }
          }
        },
        error => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocessSave");
          this.toastError("Falha na execução.");
        }
      );      
    },
    get() {
      if (this.processing) return;

      this.processing = true;

      this.$wait.start("inprocess");
      this.showWaitAboveAll();
      integracaoService.get(this.getLoggedId(), this.id).then(
        response => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocess");

          if (this.validateJSON(response))
          {
              this.form.loaded = true;
              this.form.id = response.id;
              this.form.name = response.name;
              this.form.url = response.url;
              this.form.active = response.active;
              this.form.ambiente = response.ambiente;
              this.form.cnpj = response.cnpj;
              this.grids.added.items=response.itens;
          }
        },
        error => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocess");
          this.toastError("Falha na execução.");
        }
      );      
    }
  },
  validations: {
    form: {
      name: { required },
      ambiente: { required },
   
    }
  },
  data () {
    return {
        processing: false,
        loading: false,
        components: { 
          
        },
        form: {

          loaded: false,
          id: '',
          name: '',
          active: 1,
    
          url: '',
          ambiente: ''
       
        },
        grids: {
          result: {
            processing: false,
            loaded: false,
            total: 0,
            currentPage: 1,
            perPage: 1000,
            items: [],
            fields: {
                key: { label: 'Chave', sortable: false},
                prod: { label: 'Valor de Produção', sortable: false },
                dev: { label: 'Valor de Desenvolvimento / Testes', sortable: false },
                actions: { label: 'Ações', sortable: false }
            },
          },
          added: {
            processing: false,
            loaded: true,
            total: 0,
            currentPage: 1,
            perPage: 1000,
            items: [],
            fields: {
                key: { label: 'Chave', sortable: false },
                prod: { label: 'Valor de Produção', sortable: false },
                dev: { label: 'Valor de Desenvolvimento / Testes', sortable: false },
                actions: { label: 'Ações', sortable: false }
            },
          }
        }
    }
  }
}
</script>

<style scoped>
.errorFormValidate {
  margin-top: 5px !important;
  margin-bottom: -10px;
}
</style>
