import Vue from 'vue';
import VueResource from "vue-resource";
import config from '@/config';

Vue.use(VueResource);

config.setapikey();

export const integracaoService = {
  list,
  get,
  save,
  executar
}


function save(loggedId,id, name, cnpj, active, ambiente,urlpost,itens) {
    let url = config.api + `/v1/admin/integracao/save`;
console.log(itens);
    let obj = {
        id_user: loggedId
        ,id
        ,name
        ,cnpj
        ,active
        ,ambiente
        ,urlpost
        ,itens
    };

    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });    
        }
    );
    return ret;
}

function get(loggedId, id) {
    let url = config.api + `/v1/admin/integracao/get?loggedId=${loggedId}&id=${id}`;

    var ret = new Promise(
    function (resolve, reject) {
        Vue.http.get(url).then(res => {
        resolve(res.body);
        }, err => {
        reject({
            error: true,
            msg: err
        });
        });
    }
  );
  return ret;
}


function list(loggedId, search, currentPage, perPage) {
    let url = config.api + `/v1/admin/integracao/list?loggedId=${loggedId}&search=${search}`;
    url = config.system.applyPagination(url, currentPage, perPage);

    var ret = new Promise(
    function (resolve, reject) {
        Vue.http.get(url).then(res => {
        resolve(res.body);
        }, err => {
        reject({
            error: true,
            msg: err
        });
        });
    }
  );
  return ret;
}

function executar(loggedId,page) {
    let url = config.api + page+`?loggedId=${loggedId}&json=1`;
    var ret = new Promise(
    function (resolve, reject) {
        Vue.http.get(url).then(res => {
        resolve(res.body);
        }, err => {
        reject({
            error: true,
            msg: err
        });
        });
    }
  );
  return ret;
}